@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: UTM_HelvetIns;
  src: url('../font/UTM_HelvetIns.ttf');
}

a:hover {
  color: inherit;
  text-decoration-line: none;
}

div::-webkit-scrollbar {
  width: 4px;
}

div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: purple;
}

button {
  outline: none;
}

.image-container {
  border: 4px solid transparent;
  background-clip: padding-box;
  box-shadow: 0 3px 6px purple, inset 0 0 6px violet;
}

.image-container::after {
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  background: linear-gradient(purple, violet);
  content: '';
  z-index: -1;
  border-radius: 16px;
}
